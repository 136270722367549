import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import ColumnsLayout from '@activebrands/core-web/components/content-layouts/ColumnsLayout';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const TwoColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();

    const { button = {}, columns = [], heading = [], headingSize = 'md', switchDesktopOrder = false } = content;

    const headingSettings = transformHeading({ type: heading?.[0]?.type, defaultType: 'h3' });
    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};
    const contentWrapperStyles = getContentWrapperStyles();

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)', ...contentWrapperStyles })}>
            <div
                className={css({
                    alignItems: ['flex-start', null, 'center'],
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'space-between',
                    marginBottom: '24px',
                    flexDirection: ['column', null, 'row'],
                })}
            >
                {heading.length > 0 && (
                    <Heading fontKeys={columnsHeadingFontKeys[headingSize]} type={headingSettings.type}>
                        {heading[0]?.text}
                    </Heading>
                )}
                {transformedButton.label && transformedButton.url && (
                    <ThemeButton
                        as={Link}
                        rel={transformedButton.rel}
                        theme={transformedButton.theme}
                        to={transformedButton.url}
                    >
                        {transformedButton.label}
                    </ThemeButton>
                )}
            </div>
            <ColumnsLayout
                $style={{ justifyContent: 'center' }}
                columnSizes={[12, null, 6]}
                gutterHeight={4}
                gutterWidth={4}
                items={columns.map((column, i) => {
                    const firstColumn = i === 0;
                    const isTextModule = column.component === 'textModule';
                    const textModuleStyle = isTextModule ? { aspectRatio: 'auto', height: '100%' } : {};

                    return (
                        <ColumnsWrapper
                            $style={{
                                display: 'flex',
                                alignItems: 'center',
                                aspectRatio: ['var(--ratio-vertical-primary)', null, null, 'var(--ratio-square)'],
                                ...textModuleStyle,
                            }}
                            columnStyle={{
                                order: firstColumn && switchDesktopOrder ? [1, null, null, null, 2] : 1,
                            }}
                            component={column.component}
                            key={column._uid}
                            numberOfItems={columns.length}
                            position={i}
                            switchDesktopOrder={switchDesktopOrder}
                        >
                            <CmsDataTransformerBlock isTwoColumns block={column} />
                        </ColumnsWrapper>
                    );
                })}
            />
        </section>
    );
};

TwoColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default TwoColumnsModule;
